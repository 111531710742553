var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"token",class:[_vm.role.id],on:{"click":_vm.setRole}},[(_vm.role.id)?_c('span',{staticClass:"icon",style:({
      backgroundImage: `url(${
        _vm.role.image && _vm.grimoire.isImageOptIn
          ? _vm.role.image
          : require('../assets/icons/' + (_vm.role.imageAlt || _vm.role.id) + '.png')
      })`,
    })}):_vm._e(),(_vm.role.firstNight || _vm.role.firstNightReminder)?_c('span',{staticClass:"leaf-left"}):_vm._e(),(_vm.role.otherNight || _vm.role.otherNightReminder)?_c('span',{staticClass:"leaf-right"}):_vm._e(),(_vm.reminderLeaves)?_c('span',{class:['leaf-top' + _vm.reminderLeaves]}):_vm._e(),(_vm.role.setup)?_c('span',{staticClass:"leaf-orange"}):_vm._e(),_c('svg',{staticClass:"name",attrs:{"viewBox":"0 0 150 150"}},[_c('path',{attrs:{"d":"M 13 75 C 13 160, 138 160, 138 75","id":"curve","fill":"transparent"}}),_c('text',{staticClass:"label mozilla",attrs:{"width":"150","x":"66.6%","text-anchor":"middle","font-size":_vm._f("nameToFontSize")(_vm.role.name)}},[_c('textPath',{attrs:{"xlink:href":"#curve"}},[_vm._v(" "+_vm._s(_vm.role.name)+" ")])])]),_c('div',{staticClass:"edition",class:[`edition-${_vm.role.edition}`, _vm.role.team]}),(_vm.role.ability)?_c('div',{staticClass:"ability"},[_vm._v(" "+_vm._s(_vm.role.ability)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }