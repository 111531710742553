var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modals.reference && _vm.roles.size)?_c('Modal',{staticClass:"characters",on:{"close":function($event){return _vm.toggleModal('reference')}}},[_c('font-awesome-icon',{staticClass:"toggle",attrs:{"icon":"cloud-moon","title":"Show Night Order"},on:{"click":function($event){return _vm.toggleModal('nightOrder')}}}),_c('h3',[_vm._v(" Character Reference "),_c('font-awesome-icon',{attrs:{"icon":"address-card"}}),_vm._v(" "+_vm._s(_vm.edition.name || "Custom Script")+" ")],1),_vm._l((_vm.rolesGrouped),function(teamRoles,team){return _c('div',{key:team,class:['team', team]},[_c('aside',[_c('h4',[_vm._v(_vm._s(team))])]),_c('ul',[_vm._l((teamRoles),function(role){return _c('li',{key:role.id,class:[team]},[(role.id)?_c('span',{staticClass:"icon",style:({
            backgroundImage: `url(${
              role.image && _vm.grimoire.isImageOptIn
                ? role.image
                : require(
                    '../../assets/icons/' +
                      (role.imageAlt || role.id) +
                      '.png',
                  )
            })`,
          })}):_vm._e(),_c('div',{staticClass:"role"},[(Object.keys(_vm.playersByRole).length)?_c('span',{staticClass:"player"},[_vm._v(_vm._s(_vm.playersByRole[role.id] ? _vm.playersByRole[role.id].join(", ") : ""))]):_vm._e(),_c('span',{staticClass:"name"},[_vm._v(_vm._s(role.name))]),_c('span',{staticClass:"ability"},[_vm._v(_vm._s(role.ability))])])])}),_c('li',{class:[team]}),_c('li',{class:[team]})],2)])}),(_vm.jinxed.length)?_c('div',{staticClass:"team jinxed"},[_c('aside',[_c('h4',[_vm._v("Jinxed")])]),_c('ul',[_vm._l((_vm.jinxed),function(jinx,index){return _c('li',{key:index},[_c('span',{staticClass:"icon",style:({
            backgroundImage: `url(${require(
              '../../assets/icons/' + jinx.first.id + '.png',
            )})`,
          })}),_c('span',{staticClass:"icon",style:({
            backgroundImage: `url(${require(
              '../../assets/icons/' + jinx.second.id + '.png',
            )})`,
          })}),_c('div',{staticClass:"role"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(jinx.first.name)+" & "+_vm._s(jinx.second.name))]),_c('span',{staticClass:"ability"},[_vm._v(_vm._s(jinx.reason))])])])}),_c('li'),_c('li')],2)]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }